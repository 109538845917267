
import './App.css';
import Web3 from 'web3'
import ERC721 from '../abis/ERC721.json'
import React, { Component } from 'react';
import BatchTransfer from '../abis/BatchTransfer.json'

class App extends Component {
  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })
    // Load smart contract: BatchTransfer
    try {
      const abi = BatchTransfer.abi
      const contractAddr = "0xa28A56bbD2b9ede5A006dBb2CA2B4Fd52b086250"
      this.setState({ contractAddr })
      const contract = new window.web3.eth.Contract(abi, contractAddr)
      this.setState({ contract })
    } catch (err) {
      window.alert(err)
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      tokens: [{ address: [{ str: "", err: "" }], id: [{ str: "", err: "" }] }],
      account: '',
      contract: null,
      contractAddr: ''
    };
  }
  
  handleChange(i, e) {
    let tokens = this.state.tokens;
    tokens[i][e.target.name]["str"] = e.target.value;
    tokens[i][e.target.name]["err"] = "";
    this.setState({ tokens });
  }

  addFormFields() {
    this.setState(({
      tokens: [...this.state.tokens, { address: [{ str: "", err: "" }], id: [{ str: "", err: "" }] }]
    }))
  }

  removeFormFields(i) {
    let tokens = this.state.tokens;
    tokens.splice(i, 1);
    this.setState({ tokens });
  }

  // error handlers
  tokensExists(web3, ERC721_abi, tokens) {

    var promises = [];
    for (var i = 0; i < tokens.length; i++) {
      var promise = new Promise((resolve, reject) => {
      if (!web3.utils.isAddress(tokens[i].address.str)) {
        throw new Error("Address is invalid")
      }
      const contract = new web3.eth.Contract(ERC721_abi, tokens[i].address.str)
      const split_tokens = tokens[i].id.str.replace(/\s/g, '').split(',')
      const error_idx = i;
      for (var j = 0; j < split_tokens.length; j++) {
        const idx = j;
          contract.methods.ownerOf(split_tokens[j]).call({ from: this.state.account })
            .then((result) => {
              if (result === this.state.account) {
                resolve("current token exists")
              } else {
                tokens[error_idx].address.err = "current address does not own this token"
                tokens[error_idx].address.str = ''
                throw new Error(tokens[error_idx].address.err)
              }
            })
            .catch((err) => {
              reject(err)
              tokens[error_idx].id.str = ''
              tokens[error_idx].id.err = "token " + split_tokens[idx] + " does not exist"
              this.setState({ tokens })
            })
      }
     })
      promises.push(promise)
    }
    return Promise.all(promises)
  }

  approveAll = (tokens, approveAddr) => {
    const web3 = window.web3
    const ERC721_abi = ERC721.abi
    try {
      this.tokensExists(web3, ERC721_abi, tokens).then((result) => {
        for (var i = 0; i < tokens.length; i++) {
          const contract = new web3.eth.Contract(ERC721_abi, tokens[i].address.str)
          contract.methods.setApprovalForAll(approveAddr, true).estimateGas()
            .then((estimateGas) => {
              web3.eth.getGasPrice().then((gasPrice) => {
                // for testing purpose //
                // const gasPrice_eth = web3.utils.fromWei(gasPrice, 'ether')
                // const estimateGasPrice = gasPrice_eth * estimateGas
                // console.log("estimateGas: ", estimateGas)
                // console.log(estimateGasPrice)
                //---------------------//
                contract.methods.setApprovalForAll(approveAddr, true).send({
                  from: this.state.account,
                  gas: estimateGas, gasPrice: gasPrice,
                })
                  .then()
                  .catch((err) => {
                    window.alert(err.message)
                  })
              })
            })
        }
      }).catch((err) => {
        if (err.code !== -32603 && err.toString() !== "Error: current address does not own this token") {
          if (err.toString().includes('.id.str is undefined'))
            window.alert("Token ID field is missing.")
          else
            window.alert(err.message)
        }
      })
    } catch (err) {
         window.alert(err.message)
    }
    
  }
    
  safeBatchTransfer = (tokens, toAcc) => {
    const web3 = window.web3
    let tokenid_list = []
    let addr_list = []
    if (toAcc === this.state.account) {
      window.alert("Warning: You are transfering to yourself.")
    }
    try {
      for (var i = 0; i < tokens.length; i++) {
        addr_list.push(tokens[i].address.str)
      }
      for (i = 0; i < tokens.length; i++) {
        const split_tokens = tokens[i].id.str.replace(/\s/g, '').split(',')
        tokenid_list.push(split_tokens)
      }
      this.state.contract.methods.batchTransfer(addr_list, tokenid_list, toAcc).estimateGas({ from: this.state.account })
        .then((estimateGas) => {
          web3.eth.getGasPrice().then((gasPrice) => {
            // for testing purpose //
            // const gasPrice_eth = web3.utils.fromWei(gasPrice, 'ether')
            // const estimateGasPrice = gasPrice_eth * estimateGas
            // console.log("estimateGas: ", estimateGas)
            // console.log(estimateGasPrice)
            //---------------------//
            this.state.contract.methods.batchTransfer(addr_list, tokenid_list, toAcc).send({
              from: this.state.account,
              gas: estimateGas, gasPrice: gasPrice,
            })
            .catch((err) => {
              window.alert(err.message)
            })

          })
        })
        .catch((err) => {
          if (toAcc === "") {
            window.alert("Please fill the Recipient's Address.")
          } 
          else
            window.alert("Something went wrong, check if all given contract addresses are approved.")
        })
    } catch (err) {
      if (err.toString().includes('.id.str is undefined'))
        window.alert("Token ID field is missing.")
      else
        window.alert(err.message)
    }
  }
  
  render() {
    return (
      <div className="container">
        <h1 className="title">
        <label> NFT Batch Transfer </label>
        </h1>
        <h2 className="description">
          <p> NFT BatchTransfer tool allows one to send multiple NFT tokens from different contracts at once.</p>
          <p> To make a transaction, input your NFT address in the first input box, and the token ID in the second
              input box. Note: Multiple token IDs can be inputted separated by the comma sign.
          </p>
        </h2>

        {/* Top Half */}
        <form onSubmit={(event) => {
          event.preventDefault()
          this.approveAll(this.state.tokens, this.state.contractAddr)
        }}>
          <label className="text header"> NFT Address & Token ID: </label>
          <button type="button" className="button-add" onClick={() => this.addFormFields()}>Add Token</button>
          
          {this.state.tokens.map((element, index) => (
            // Top line
            <div className="row">
              <div className="form-group col-md-6">
                <div className="input-group">
                  <label className="text tokenID">Contract address:</label>
                  <input type="text" className="form-control rounded" name="address" placeholder='e.g. 0x43212eB37BE18fF28A58D47186aF9Db9650bF341' value={element.address.str || ""} onChange={e => this.handleChange(index, e)} />
                </div>
                  <div className="col-8 ml-auto">
                    <small style={{ fontSize: 12, color: 'red' }}>{this.state.tokens[index].address.err}</small>
                  </div>
              </div>
              <div className="form-group col-md-5">
                <div className="input-group">
                  <label className="text tokenID">Token ID:</label>                    
                  <input type="text" className="form-control rounded"  name="id" placeholder='e.g. 1, 2' value={element.id.str || ""} onChange={e => this.handleChange(index, e)} />
                </div>
                <div className="err-msg col-9 ml-auto">
                  <small style={{ fontSize: 12, color: 'red' }}>{this.state.tokens[index].id.err}</small>
                </div>
              </div>
              <div className="form-group col-md-1">
                <div className="input-group-btn">
                {
                  index ? 
                  <button type="button" className="button-remove" onClick={() => this.removeFormFields(index)}>Remove</button> 
                  : null
                }
                </div>
              </div>
            </div>

          ))}

        {/* Line under - for buttons */}
          <div className="button-section">
            <button className="button-approve" type="approve">Approve</button>
          </div>
        </form>

        {/* Bottom Half */}
        <form onSubmit={(event) => {
          event.preventDefault()
          const toAcc = this.toAcc.value.toString()
          this.safeBatchTransfer(this.state.tokens, toAcc)
          }}>
          
          {/* Recipient Address input box */}
          <label className="text recipient"> Recipient's Address: </label>
          <input type='text' className='form-control mb-1' placeholder='e.g. 0x6DFFA3aeE6D527B533aFA3456C5608C3E6E441ac' ref={(input) => { this.toAcc = input }} />
          {/* Transfer button */}
          <input type='submit' className='btn btn-block btn-primary' value='transfer' />
        </form>

      </div>
    );
    }
}

export default App;
